import React, { useEffect, useRef, useState } from "react"
import PageRoot from "../../../components/PageRoot"
import { Alert, Button, Col, Row, Space, Spin, Steps } from "antd"
import { useTranslation } from "react-i18next"
import SignUpStepChoosePlanForm from "../../../components/signUp/SignUpStepChoosePlanForm"
import SignUpStepPaymentForm from "../../../components/signUp/SignUpStepPaymentForm"
import SignUpStepCompleteForm from "../../../components/signUp/SignUpStepCompleteForm"
import {
  devLog,
  getErrorMessageByCode,
  isSubscriptionValid,
  pushBeginCheckoutEvent,
  pushPurchaseEvent,
  uiHandleSuccess,
} from "../../../utils"
import { gql, useMutation, useQuery } from "@apollo/client"
import LoadingScreen from "../../../components/LoadingScreen"
import ErrorScreen from "../../../components/ErrorScreen"
import { SIGN_UP_DATA } from "../../../enums/UserPreferenceKeys"
import useCommonQueries from "../../../hooks/useCommonQueries"
import { navigate } from "gatsby"
import { SUBSCRIPTION } from "../../../enums/Plans"
import "../../../components/signUp/SingUpStep.less"
import useBraintree from "../../../hooks/useBraintree"
import QueryString from "query-string"
import {
  SUBSCRIPTION_DISABLED,
  SUBSCRIPTION_STEP_COMPLETE,
  SUBSCRIPTION_STEP_CUSTOMER_PROFILE,
  SUBSCRIPTION_STEP_PAYMENT,
  SUBSCRIPTION_STEP_SELECT_PLAN,
} from "../../../config"
import SignUpStepCompileBillingProfileForm from "../../../components/signUp/SignUpStepCompileBillingProfileForm"
import { getPlanDataById } from "../../../utils"

const { Step } = Steps

const GET_USER_SIGNUP_DATA = gql`
  query userSignUpData($couponCode: String, $now: timestamptz) {
    user_settings(where: { key: { _eq: "SIGN_UP_DATA" } }) {
      key
      value
    }
    user_subscriptions(order_by: { created_at: desc }, limit: 1) {
      state
      created_at
      end_at
      id
      start_at
      user_id
      braintree_subscription_id
    }
    braintree_customers {
      customer_id
    }
    coupon_codes(
      where: {
        code: { _eq: $couponCode }
        criterion: {
          _and: [
            {
              _or: [
                { exclusive_for_content_type: { _eq: SUBSCRIPTION } }
                { exclusive_for_content_type: { _is_null: true } }
              ]
            }
            {
              _or: [
                { start_at: { _lte: $now } }
                { start_at: { _is_null: true } }
              ]
            }
            { _or: [{ end_at: { _gt: $now } }, { end_at: { _is_null: true } }] }
          ]
        }
        consumed_at: { _is_null: true }
      }
    ) {
      code
      criterion {
        description
        discount_type
        end_at
        exclusive_for_content_id
        exclusive_for_content_type
        id
        percentage
        picture {
          s3_key
        }
        short_description
        start_at
        subtitle
        title
        value
      }
    }
  }
`

const UPSERT_USER_BASIC_PROFILE_MUTATION = gql`
  mutation insertUserProfile($data: user_profiles_insert_input!) {
    insert_user_profiles_one(
      object: $data
      on_conflict: {
        constraint: user_profiles_user_id_key
        update_columns: [
          address
          firstname
          lastname
          phone_number
          email
          newsletter
        ]
      }
    ) {
      id
    }
  }
`

const SignUpPage = ({ location }) => {
  const params = QueryString.parse(location.search)
  const [now, setNow] = useState(new Date())

  const { t } = useTranslation()
  const [currentStepIndex, setCurrentStepIndex] = useState(0)
  const [customerId, setCustomerId] = useState(null)
  const [signupData, setSignupData] = useState({})
  const [refreshing, setRefreshing] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const formRef = useRef()

  const [formReady, setFormReady] = useState(false)

  const [stepError, setStepError] = useState(null)

  console.log({ signupData })

  const { loading, error, data, refetch } = useQuery(GET_USER_SIGNUP_DATA, {
    fetchPolicy: "network-only",
    variables: {
      couponCode: params.coupon || "",
      now,
    },
  })
  const [upsertUserBasicProfile] = useMutation(
    UPSERT_USER_BASIC_PROFILE_MUTATION
  )
  const { createCustomer, subscribe } = useBraintree()
  const { upsertUserSetting } = useCommonQueries()

  const next = () => {
    formRef.current?.submit()
  }

  const prev = () => {
    setCurrentStepIndex(currentStepIndex - 1)
  }

  devLog({ customerId })

  const scrollToActionButtons = () => {
    const element = document.querySelector("#steps-actions")
    element?.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    })
  }

  useEffect(() => {
    if (refreshing) {
      setRefreshing(false)
    }
  }, [refreshing])

  useEffect(() => {
    if (currentStepIndex !== 0) {
      return
    }

    if (loading || refreshing) {
      return
    }

    if (!signupData.planId) {
      const params = QueryString.parse(window.location.search)
      if (!params.planId) {
        return
      }

      setSignupData({
        ...signupData,
        planId: params.planId,
      })

      setTimeout(scrollToActionButtons, 500)
    } else {
      setTimeout(scrollToActionButtons, 500)
    }
  }, [signupData, loading, refreshing, currentStepIndex])

  useEffect(() => {
    const params = QueryString.parse(window.location.search)
    if (params.planId) {
      // scroll to #steps-actions
      const element = document.getElementById("steps-actions")
      if (element) {
        element.scrollIntoView({ behavior: "smooth" })
      }
    }
  }, [])

  useEffect(() => {
    if (data) {
      if (data.user_subscriptions[0]) {
        if (isSubscriptionValid(data.user_subscriptions[0])) {
          if (data.user_subscriptions[0].state === "Canceled") {
            return
          }

          uiHandleSuccess({
            message: t("message:subscriptionAlreadyActive"),
            action: () => {
              navigate("/")
            },
          })
          return
        }
      }

      if (data.braintree_customers[0]) {
        setCustomerId(data.braintree_customers[0].customer_id)
      }

      const _sigUpData = data.user_settings.find(
        setting => setting.key === SIGN_UP_DATA
      )

      if (_sigUpData) {
        try {
          let remoteSignupData = JSON.parse(_sigUpData.value)
          delete remoteSignupData.error
          if (params.planId) {
            remoteSignupData.planId = params.planId
          }
          setSignupData(remoteSignupData)
          setRefreshing(true)
        } catch (error) {
          devLog({ parseRemoteSettingDataError: error })
        }
      }
    }
  }, [data])

  useEffect(() => {
    if (SUBSCRIPTION_DISABLED) {
      navigate("/", {
        replace: true,
      })
    }
  }, [])

  useEffect(() => {
    const planData = getPlanDataById(signupData.planId)

    if (currentStepIndex === SUBSCRIPTION_STEP_SELECT_PLAN) {
      pushBeginCheckoutEvent({
        id: "subscription",
        name: planData.label,
        price: planData.price,
      })
    }

    if (currentStepIndex === SUBSCRIPTION_STEP_COMPLETE && !signupData.error) {
      pushPurchaseEvent({
        itemId: "subscription",
        name: planData.label,
        price: planData.price,
      })
    }
  }, [currentStepIndex, signupData])

  if (SUBSCRIPTION_DISABLED) {
    return null
  }

  if (loading || refreshing) {
    return <LoadingScreen />
  }

  if (error) {
    return <ErrorScreen error={error} />
  }

  const steps = [
    {
      title: t("signUp:step.plan"),
      ContentComponent: SignUpStepChoosePlanForm,
    },
    {
      title: t("signUp:step.upsertCustomerProfile"),
      ContentComponent: SignUpStepCompileBillingProfileForm,
    },
    {
      title: t("signUp:step.payment"),
      ContentComponent: SignUpStepPaymentForm,
    },
    {
      title: t("signUp:step.complete"),
      ContentComponent: SignUpStepCompleteForm,
    },
  ]

  const handleFormSubmit = async values => {
    setSubmitting(true)
    const newSignUpData = { ...signupData, ...values, plan: "SUBSCRIPTION" }
    devLog(newSignUpData)
    setSignupData(newSignUpData)
    console.log({ newSignUpData })

    const upsertData = {
      key: SIGN_UP_DATA,
      value: JSON.stringify(newSignUpData),
    }

    try {
      await upsertUserSetting(upsertData)
    } catch (error) {
      devLog({ upsertUserSettingError: error })
    }

    switch (currentStepIndex) {
      case SUBSCRIPTION_STEP_SELECT_PLAN:
        break
      case SUBSCRIPTION_STEP_CUSTOMER_PROFILE:
        // upsert braintree customer (billing_profile)
        if (customerId) {
          // skip customer creation if id exists
          break
        }
        try {
          await createCustomer({
            firstname: values.firstname,
            lastname: values.lastname,
            vat_number: values.tax_code,
            phone_number: values.phone_number,
            email: values.email,
            billing_address: values.billing_address,
          })

          await refetch()
        } catch (error) {
          setStepError(error)
          setSubmitting(false)
          return
        }

        break
      case SUBSCRIPTION_STEP_PAYMENT:
        try {
          await subscribe({
            ...signupData,
            ...values,
            customer_id: customerId,
          })
        } catch (error) {
          setSignupData({ ...newSignUpData, error })
        }
      default:
        break
    }

    setStepError(null)
    setCurrentStepIndex(currentStepIndex + 1)
    setSubmitting(false)
  }

  const ContentComponent = steps[currentStepIndex].ContentComponent

  return (
    <PageRoot title={t("label:subscription")} noLayout={true} isDark={false}>
      <Row justify="space-around">
        <Col xs={22} sm={22} md={20} lg={16} xl={14} xxl={14}>
          <div className="sing-up-page-content">
            <Space direction="vertical" size={50} style={{ width: "100%" }}>
              <div className="steps-content">
                <Spin spinning={submitting}>
                  <ContentComponent
                    initialValues={{
                      planId: params.planId,
                      ...signupData,
                      coupon_code: data.coupon_codes[0]?.code ?? "",
                    }}
                    coupon={data.coupon_codes[0]}
                    formRef={formRef}
                    onFinish={handleFormSubmit}
                    onReady={() => {
                      setFormReady(true)
                    }}
                  />
                </Spin>
              </div>
              <div className="steps-indicator">
                <Row justify="center">
                  <Col xs={20} sm={20} md={12} lg={12} xl={10} xxl={6}>
                    <Steps
                      progressDot={false}
                      size="small"
                      current={currentStepIndex}
                    >
                      {steps.map(item => (
                        <Step key={item.title} />
                      ))}
                    </Steps>
                  </Col>
                </Row>
              </div>

              {stepError && (
                <Alert
                  type="error"
                  message={getErrorMessageByCode(stepError)}
                />
              )}

              <Spin spinning={submitting}>
                <div
                  id="steps-actions"
                  className="steps-actions"
                  style={{ height: 300 }}
                >
                  {currentStepIndex > 0 && currentStepIndex < steps.length - 1 && (
                    <Button
                      className="step-button"
                      type="dashed"
                      style={{ margin: "0 8px" }}
                      disabled={submitting}
                      onClick={() => prev()}
                    >
                      {t("button:previous")}
                    </Button>
                  )}
                  {currentStepIndex < steps.length - 1 &&
                    currentStepIndex !== 0 && (
                      <Button
                        className="yellowsp step-button"
                        disabled={submitting || !signupData.planId}
                        type="primary"
                        onClick={() => next()}
                      >
                        {t("button:next")}
                      </Button>
                    )}
                  {currentStepIndex === 0 && (
                    <Button
                      className="yellowsp step-button"
                      disabled={submitting || !formReady}
                      type="primary"
                      onClick={() => next()}
                    >
                      {t("form:label.selectPlan")}
                    </Button>
                  )}
                  {currentStepIndex === steps.length - 1 && (
                    <>
                      {signupData.error ? (
                        <Button
                          className="step-button"
                          type="danger"
                          disabled={submitting}
                          onClick={() => {
                            const { error, ...otherProps } = signupData
                            setSignupData({ ...otherProps })
                            setStepError(null)
                            setCurrentStepIndex(SUBSCRIPTION_STEP_PAYMENT)
                          }}
                          style={{
                            color: "white",
                          }}
                        >
                          {t("button:retry")}
                        </Button>
                      ) : (
                        <Button
                          className="yellowsp step-button"
                          disabled={submitting}
                          type="primary"
                          onClick={() => {
                            window.location.replace("/")
                          }}
                        >
                          {t("button:startNowOnSportScience")}
                        </Button>
                      )}
                    </>
                  )}
                </div>
              </Spin>
            </Space>
          </div>
        </Col>
      </Row>
    </PageRoot>
  )
}

export default SignUpPage
