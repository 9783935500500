import dropin from "braintree-web-drop-in"
import { devLog } from "../utils"
import useBraintree from "./useBraintree"

const { useEffect } = require("react")
const { useState } = require("react")
const useDropin = (containerId, price, paypalCredit = true) => {
  const { getToken } = useBraintree()

  const [initialized, setInitialized] = useState(false)

  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [clientInstance, setClientInstance] = useState(null)
  const [dropinInstance, setDropinInstance] = useState(null)
  const [threeDSecureInstance, setThreeDSecureInstance] = useState(null)
  const [dataCollectorInstance, setDataCollectorInstance] = useState(null)
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null)
  const [deviceData, setDeviceData] = useState(null)

  const onPaymentMethodRequestable = event => {
    setSelectedPaymentMethod(event.type)
  }

  useEffect(() => {
    const init = async () => {
      setInitialized(true)

      const token = await getToken()
      try {
        // const _client = await braintree.client.create({
        //   authorization: token,
        // })
        //
        // devLog({ _client })
        //
        // const _threeDSecure = await braintree.threeDSecure.create({
        //   version: 2, // Will use 3DS 2 whenever possible
        //   client: _client,
        // })
        //
        // devLog({ _threeDSecure })
        //
        // const _dataCollector = await braintree.dataCollector.create({
        //   client: _client,
        //   paypal: true,
        // })
        //
        // devLog({ _dataCollector })

        const dropinOptions = {
          authorization: token,
          container: containerId,
          locale: process.env.GATSBY_LANG,
          card: {},
          vaultManager: process.env.NODE_ENV !== "production",
          threeDSecure: true,
          paypal: {
            flow: "vault",
            amount: price,
            currency: process.env.GATSBY_CURRENCY,
            landingPageType: "login", // hard code this so we get a consistent experience for e2e tests
          },
        }

        if (paypalCredit) {
          dropinOptions.paypalCredit = {
            flow: "checkout",
            amount: price,
            currency: process.env.GATSBY_CURRENCY,
            landingPageType: "login", // hard code this so we get a consistent experience for e2e tests
          }
        }

        const _dropin = await dropin.create(dropinOptions)

        devLog({ _dropin })

        _dropin.on("paymentMethodRequestable", onPaymentMethodRequestable)

        // setDeviceData(_dataCollector.deviceData)
        // setClientInstance(_client)
        // setThreeDSecureInstance(_threeDSecure)
        // setDataCollectorInstance(_dataCollector)
        setDropinInstance(_dropin)
        setLoading(false)
      } catch (error) {
        setLoading(false)
        setError(error)
      }
    }

    if (containerId && price) {
      if (!initialized) {
        init()
      }
    }

    return () => {
      if (dropinInstance) {
        dropinInstance.teardown()
      }

      if (threeDSecureInstance) {
        threeDSecureInstance.teardown()
      }

      if (clientInstance) {
        clientInstance.teardown()
      }

      if (dataCollectorInstance) {
        dataCollectorInstance.teardown()
      }
    }
  }, [containerId, price])

  return {
    loading,
    error,
    // clientInstance,
    // threeDSecureInstance,
    // dataCollectorInstance,
    dropinInstance,
    selectedPaymentMethod,
    deviceData,
  }
}

export default useDropin
