import React, { useEffect, useMemo, useState } from "react"
import { Trans, useTranslation } from "react-i18next"
import { Card, Col, Divider, Row, Space, Button } from "antd"
import { SUBSCRIPTION } from "../../enums/Plans"
import "./PlanSelector.less"
import { CheckCircleOutlined } from "@ant-design/icons"
import { PERCENTAGE, VALUE } from "../../enums/DiscountTypes"
import { devLog, getPlanDataById } from "../../utils"
import QueryString from "query-string"
import { element } from "prop-types"

const PlanPreview = ({ data, active, onClick }) => {
  const { t } = useTranslation()
  const {
    plan,
    title,
    description,
    features,
    priceLabel,
    finalPrice,
    monthlyFee,
    period,
    highlighted,
    paymentMode,
  } = data

  const priceLabelText = useMemo(() => {
    if (!finalPrice) {
      return (
        <>
          <h2 style={{ margin: 0 }}>{monthlyFee}</h2>
          <div className="plan-description">
            {t("plan:subscription.annualPayment")}
          </div>
        </>
      )
    }

    return (
      <>
        <h2 className="stroked" style={{ position: "absolute", bottom: 45 }}>
          {priceLabel}
        </h2>
        <h1>{finalPrice}</h1>
      </>
    )
  }, [priceLabel, finalPrice, monthlyFee, t])

  const priceParts = priceLabel.split(
    process.env.GATSBY_DECIMAL_SEPARATOR || "."
  )

  return (
    <div
      className={`plan-preview ${active ? "active" : ""}`}
      style={{
        background: highlighted
          ? "linear-gradient(0deg, #C1A875 0%, #C1A875 100%), radial-gradient(97.84% 92.24% at 0% -8.66%, #FFF 0%, rgba(255, 255, 255, 0.00) 100%), linear-gradient(76deg, #576265 -26.43%, #9EA1A1 -6.51%, #848B8A 13.45%, #576265 33.81%, #576265 69.75%, #757A7B 82.8%, #576265 96.54%)"
          : "transparent",
      }}
    >
      <div className="header-label">
        {highlighted ? t("label:mostChosenByUser") : <span>&nbsp;&nbsp;</span>}
      </div>

      <Card hoverable onClick={onClick}>
        <Space direction="vertical" size={36} style={{ width: "100%" }}>
          <Space className="brand-badge">
            <div>
              <img src="/assets/images/favicon.png" alt="SportScience" />
            </div>
            <div>SportScience</div>
            <div>
              <img src="/assets/images/premium-badge.svg" alt="Premium" />
            </div>
          </Space>

          <div>
            <div className="price-container">
              <h2 className="period">{period}</h2>
              <Space direction="horizontal" align="start">
                <div className="price">{priceParts[0]}</div>
                <div className="payment-mode" direction="vertical" size={0}>
                  <b>
                    {process.env.GATSBY_DECIMAL_SEPARATOR || "."}
                    {priceParts[1]}
                  </b>
                  <p>{paymentMode}</p>
                </div>
              </Space>
            </div>
            <Button
              block
              className="yellowsp"
              onClick={(evt)=>{
                evt.preventDefault()
                evt.stopPropagation()
                onClick && onClick()
              }}
              style={{ marginTop: 21 }}
            >
              {t("button:chooseThisPlan")}
            </Button>
          </div>
          <ul className="plan-features">
            {features.map((feature, index) => (
              <li key={index} className="feature-row">
                <Space align="start">
                  <CheckCircleOutlined fill="#00B712" />
                  {feature}
                </Space>
              </li>
            ))}
          </ul>
        </Space>
      </Card>
    </div>
  )
}

const planColSetting = {
  xs: 24,
  sm: 24,
  md: 12,
  lg: 12,
  xl: 8,
  xxl: 8,
}

const getFinalPriceFromCoupon = ({ coupon, planData }) => {
  devLog({ coupon })
  if (!coupon) {
    return null
  }

  const planPrice = planData.price

  devLog({ planPrice })

  if (coupon.criterion) {
    switch (coupon.criterion.discount_type) {
      case VALUE:
        return planPrice - coupon.criterion.value
      case PERCENTAGE:
        return planPrice * (1 - coupon.criterion.percentage)
    }
  }

  return null
}

const PlanSelector = ({ onPlanSelect, value, onChange, coupon }) => {
  const [selectedPlan, setSelectedPlan] = useState(value)
  const { t } = useTranslation()

  const planData = getPlanDataById(selectedPlan)

  const finalPrice = getFinalPriceFromCoupon({ coupon, planData })

  const plans = [
    //{
    //  plan: FREE,
    //  title: t("plan:free.title"),
    //  description: t("plan:free.description"),
    //  features: [t("plan:free.feature1"), t("plan:free.feature2")],
    // priceLabel: t("plan:free.priceLabel"),
    //},
    {
      plan: SUBSCRIPTION,
      planId: process.env.GATSBY_BRAINTREE_SUBSCRIPTION_MONTHLY_PLAN_ID,
      title: t("plan:subscription.monthly.title"),
      description: t("plan:subscription.monthly.description"),
      features: [
        t("plan:subscription.feature1"),
        t("plan:subscription.feature2"),
        t("plan:subscription.feature3"),
        t("plan:subscription.feature4"),
        t("plan:subscription.feature5"),
      ],
      priceLabel: t("plan:subscription.monthly.priceLabel"),
      finalPrice:
        finalPrice &&
        t("plan:subscription.discountedPriceLabel", { finalPrice }),
      monthlyFee: t("plan:subscription.monthly.monthlyFeeLabel"),
      period: t("plan:subscription.monthly.periodLabel"),
      paymentMode: t("plan:subscription.monthly.paymentModeLabel"),
    },
    {
      plan: SUBSCRIPTION,
      planId: process.env.GATSBY_BRAINTREE_SUBSCRIPTION_SEASONAL_PLAN_ID,
      title: t("plan:subscription.seasonal.title"),
      description: t("plan:subscription.seasonal.description"),
      features: [
        t("plan:subscription.feature1"),
        t("plan:subscription.feature2"),
        t("plan:subscription.feature3"),
        t("plan:subscription.feature4"),
        t("plan:subscription.feature5"),
      ],
      priceLabel: t("plan:subscription.seasonal.priceLabel"),
      finalPrice:
        finalPrice &&
        t("plan:subscription.discountedPriceLabel", { finalPrice }),
      monthlyFee: t("plan:subscription.seasonal.monthlyFeeLabel"),
      period: t("plan:subscription.seasonal.periodLabel"),
      paymentMode: t("plan:subscription.seasonal.paymentModeLabel"),
    },
    {
      plan: SUBSCRIPTION,
      planId: process.env.GATSBY_BRAINTREE_SUBSCRIPTION_ANNUAL_PLAN_ID,
      title: t("plan:subscription.annual.title"),
      description: t("plan:subscription.annual.description"),
      features: [
        t("plan:subscription.feature1"),
        t("plan:subscription.feature2"),
        t("plan:subscription.feature3"),
        t("plan:subscription.feature4"),
        t("plan:subscription.feature5"),
        t("plan:subscription.feature6"),
        t("plan:subscription.feature7"),
        t("plan:subscription.feature8"),
        t("plan:subscription.feature9"),
      ],
      priceLabel: t("plan:subscription.annual.priceLabel"),
      finalPrice:
        finalPrice &&
        t("plan:subscription.discountedPriceLabel", { finalPrice }),
      monthlyFee: t("plan:subscription.annual.monthlyFeeLabel"),
      period: t("plan:subscription.annual.periodLabel"),
      paymentMode: t("plan:subscription.annual.paymentModeLabel"),
      highlighted: true,
    },
  ]

  const handlePlanSelect = plan => {
    onPlanSelect && onPlanSelect(plan)
    onChange && onChange(plan)
    setSelectedPlan(plan)
  }

  useEffect(() => {}, [])

  useEffect(() => {
    if (!selectedPlan) {
      const params = QueryString.parse(window.location.search)
      if (params.planId) {
        setSelectedPlan(params.planId)
      }
    }
  }, [selectedPlan])

  const equalizeCardHeight = () => {
    // Equalize card height
    const elements = document.querySelectorAll(".plan-features")
    let height = 200
    elements.forEach(element => {
      if (element.offsetHeight > height) {
        height = element.offsetHeight
      }
    })

    elements.forEach(element => {
      element.style.height = `${height}px`
    })
  }

  useEffect(() => {
    setTimeout(equalizeCardHeight, 300)
  }, [])

  return (
    <div className="plan-selector">
      <div style={{ marginBottom: 34 }}>
        <h1 className="plan-selector-title">{t("label:planSelectorTitle")}</h1>
        <p className="plan-selector-description">
          {t("label:planSelectorDescription")}
        </p>
      </div>
      <Row justify="center" gutter={[16, 16]}>
        {plans.map((item, index) => (
          <Col {...planColSetting} key={index}>
            <PlanPreview
              data={item}
              active={selectedPlan === item.planId}
              // active={item}
              onClick={() => {
                handlePlanSelect(item.planId)
              }}
            />
          </Col>
        ))}
      </Row>
    </div>
  )
}

export default PlanSelector
