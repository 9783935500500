import React, { useEffect, useMemo, useState } from "react"
import { Col, Divider, Form, Row, Space, Spin } from "antd"
import { useTranslation } from "react-i18next"
import { FREE } from "../../enums/Plans"
import CouponSelector from "../customFormItems/CouponSelector"
import { SUBSCRIPTION } from "../../enums/ItemTypes"
import {
  devLog,
  formatPrice,
  getPlanDataById,
  pushBeginCheckoutEvent,
  uiHandleError,
} from "../../utils"
import ErrorScreen from "../ErrorScreen"
import usePaymentFormItems from "../forms/usePaymentFormItems"
import useDropin from "../../hooks/useDropin"
import { PERCENTAGE } from "../../enums/DiscountTypes"
import { THREED_SECURE_FAILED } from "../../../functions/lib/error-codes"
import { CheckCircleFilled } from "@ant-design/icons"

const SignUpStepPaymentForm = ({
  initialValues,
  onFinish,
  formRef,
  coupon,
  onReady,
}) => {
  const { t } = useTranslation()

  const [selectedCoupon, setSelectedCoupon] = useState(coupon)
  const [finalPrice, setFinalPrice] = useState(0)

  const [planData, setPlanData] = useState(
    getPlanDataById(initialValues.planId)
  )

  const [requesting, setRequesting] = useState(false)

  console.log({ planData })

  const formItems = usePaymentFormItems()

  const { loading, error, dropinInstance, threeDSecureInstance } = useDropin(
    "#dropin-container",
    planData?.price,
    false
  )

  useEffect(() => {
    if (loading) {
      return
    }

    if (error) {
      return
    }

    if (!planData) {
      return
    }

    let _discount = 0
    let _finalPrice = Number(planData?.price)

    if (selectedCoupon) {
      const { value, percentage, discount_type } = selectedCoupon.criterion
      if (discount_type === PERCENTAGE) {
        _discount = _finalPrice * percentage
      } else {
        if (value >= _finalPrice) {
          uiHandleError({
            error: new Error(t("error:couponExceeded")),
          })
          setSelectedCoupon(null)
          return
        }
        _discount = value
      }
      _finalPrice = Math.max(0, _finalPrice - _discount)
    }

    setFinalPrice(_finalPrice)
  }, [selectedCoupon, loading, error, planData])

  useEffect(() => {
    if (initialValues.planId) {
      const planData = getPlanDataById(initialValues.planId)
      setPlanData(planData)
    } else {
      setPlanData(null)
    }
  }, [initialValues])

  useEffect(() => {
    if (!planData) {
      return null
    }

    pushBeginCheckoutEvent({
      id: "0",
      price: planData.price,
      name: planData.label,
    })
  }, [planData])

  useEffect(() => {
    if (!dropinInstance) {
      return
    }

    onReady && onReady()
  }, [dropinInstance])

  const priceLabel = useMemo(() => {
    if (loading) {
      return <Spin spinning />
    }

    const price = planData.price

    if (price !== finalPrice) {
      return (
        <span>
          <span className="stroked-price">{formatPrice(price)}</span>
          <span className="final-price">{formatPrice(finalPrice)}</span>
        </span>
      )
    }

    return <span>{formatPrice(price)}</span>
  }, [loading, error, finalPrice, planData])

  const handleSubmit = values => {
    if (requesting) {
      return
    }

    if (!dropinInstance) {
      return
    }

    if (initialValues.plan === FREE) {
      return onFinish()
    }

    setRequesting(true)

    dropinInstance.requestPaymentMethod(
      {
        threeDSecure: {
          amount: planData.price,
          challengeRequested: true,
        },
      },
      (requestPaymentMethodErr, payload) => {
        setRequesting(false)
        if (requestPaymentMethodErr) {
          // TODO handle error
          return uiHandleError({ error: requestPaymentMethodErr })
        }

        if (requestPaymentMethodErr) {
          return uiHandleError({ error: requestPaymentMethodErr })
        }

        if (payload.type === "CreditCard") {
          if (!payload.liabilityShifted && !payload.liabilityShiftPossible) {
            return uiHandleError({
              error: new Error(THREED_SECURE_FAILED),
            })
          } else {
            onFinish({
              ...values,
              paymentMethodNonce: payload.nonce,
              deviceData: payload.deviceData,
              finalPrice,
            })
          }
        } else {
          onFinish({
            ...values,
            paymentMethodNonce: payload.nonce,
            deviceData: payload.deviceData,
            finalPrice,
          })
        }
      }
    )
  }

  useEffect(() => {
    if (initialValues.plan === FREE) {
      formRef.current.submit()
    }
  }, [formRef.current])

  return (
    <Spin spinning={requesting}>
      <Form
        ref={formRef}
        initialValues={initialValues}
        onFinish={handleSubmit}
        layout={"vertical"}
      >
        <Row justify="center">
          <Col xs={22} sm={22} md={20} lg={20} xl={12} xxl={12}>
            <div className="sign-up-step-header">
              <h1>{t("form:signUpStep.payment.title")}</h1>
              <p>{t("form:signUpStep.payment.description")}</p>
              <Divider />
              <Row justify="space-between">
                <Col>
                  <Space>
                    <CheckCircleFilled
                      style={{
                        color: "#F2EA00",
                      }}
                    />
                    <b>{planData?.label}</b>
                  </Space>
                </Col>
                <Col>
                  <b>{priceLabel}</b>
                </Col>
              </Row>
              <Divider />
            </div>
          </Col>
        </Row>

        {initialValues.plan === FREE ? null : (
          <>
            <Form.Item label={t("form:label.couponCode")} name="coupon_code">
              <CouponSelector
                isValid={Boolean(selectedCoupon)}
                placeholder={t("form:label.couponCode")}
                size="large"
                itemType={SUBSCRIPTION}
                onCouponSelect={coupon => {
                  devLog({ coupon })
                  setSelectedCoupon(coupon)
                }}
              />
            </Form.Item>

            {Boolean(selectedCoupon) && (
              <p
                style={{
                  padding: 20,
                  textAlign: "center",
                  border: "1px solid orange",
                  backgroundColor: "#ffe6a2",
                }}
              >
                {t("warning:subscription3DsCheckWithCouponWarning")}
              </p>
            )}

            <Spin spinning={loading}>
              <div id="dropin-container" />
              {error && <ErrorScreen error={error} />}
            </Spin>
          </>
        )}
      </Form>
    </Spin>
  )
}

export default SignUpStepPaymentForm
