import React, { useEffect } from "react"
import { Col, Form, Row } from "antd"
import { useTranslation } from "react-i18next"
import PlanSelector from "../customFormItems/PlanSelector"

const SignUpStepChoosePlanForm = ({
  initialValues,
  onFinish,
  formRef,
  coupon,
  onReady
}) => {
  const { t } = useTranslation()

  useEffect(()=>{
    onReady && onReady()
  }, [])

  return (
    <Form
      ref={formRef}
      initialValues={initialValues}
      onFinish={onFinish}
      layout={"vertical"}
    >
      <Form.Item
        label={t("form:label.selectPlan")}
        name="planId"
        rules={[
          {
            required: true,
            message: t("form:error.selectAPlan"),
          },
        ]}
      >
        <PlanSelector
          coupon={coupon}
          onPlanSelect={() => {
            const element = document.querySelector("#steps-actions")
            element?.scrollIntoView({
              behavior: "smooth",
              block: "end",
              inline: "nearest",
            })
          }}
        />
      </Form.Item>
    </Form>
  )
}

export default SignUpStepChoosePlanForm
